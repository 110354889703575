/**
 * api 接口统一管理
 */

import service from "./http"

export const getTest = () => service.get('/api/config/district?parameters')

// 登录接口
export const doLogin = (data:object) => service.post('/api/login',data)

// 注册接口
export const doRegister = (data:object) => service.post('/api/reg',data)

// 轮播
export const getBanner = (location:object) => service.get('/api/common/slide_show',{params:location})

// 底部信息
export const getBottomInfo = () => service.get('/api/common/contact_us')

// 首页 公告列表
export const getAfficheIndex = () => service.get('/api/affiche/index')

// 公告
export const afficheDetail = (id:string) => service.get(`/api/affiche/info/${id}`)

//改变 详情
export const changeDetail = (id:string) => service.get(`/api/affiche/info/change/${id}`)

// 评标详情
export const reviewDetail = (affiche_id:string) => service.get(`/api/affiche/info/review/${affiche_id}`)

// 中标详情
export const resultDetail = (affiche_id:string) => service.get(`/api/affiche/info/result/${affiche_id}`)

// 首页 业界动态
export const getNewsIndex = () => service.get('/api/news/index')

// 首页 党建工作
export const getPartyBuildingIndex = () => service.get('/api/party_building_work/index')

// 业界动态详情
export const newsDetail = (id:string) => service.get(`/api/news/${id}`)

// 党建工作详情
export const partyBuildingWorkDetail = (id:string) => service.get(`/api/party_building_work/${id}`)

// 关于我们
export const aboutUs = () => service.get('/api/common/about_us')

// 获取公告列表
export const getAfficheList = (data:object) => service.get('/api/affiche',{params:data})

// 获取业界动态
export const getNewsList = (data:object) =>service.get('/api/news',{params:data})

// 获取党建工作列表
export const getPBWList = (data:object) => service.get('/api/party_building_work',{params:data})

// 法律法规 列表
export const getLawList = (data:object) => service.get('/api/law',{params:data})

// 法律分类
export const getLawKinds = () => service.get('/api/law/classify')

// 法律详情
export const getLawDetail = (id:string) => service.get(`/api/law/${id}`)

// 帮助中心 分类
export const getHelpKinds = () => service.get('/api/helper/classify')

// 帮助中心 列表
export const getHelpList = (data:object) => service.get('/api/helper',{params:data})

// 帮助详情
export const helpDetail = (id:string) => service.get(`/api/helper/${id}`)

// 获取登录用户信息
export const userInfo = () => service.get('/api/user/info')

// 文件上传token
export const uploadsToken = () =>service.get('/api/upload_token')

// 文件下载token
export const downLoadToken = (id:string) =>service.get(`/api/download_url/${id}`)

// 订单添加
export const addOrder = (affiche_id:string,affiche_a_block_id:string)=>service.post(`/api/order/${affiche_id}/${affiche_a_block_id}/add`)

// 确认订单
export const confirmOrder = (order_id:string) => service.patch(`/api/order/${order_id}/confirm`)

// 取消订单
export const cancelOrder = (order_id:string) => service.patch(`/api/order/${order_id}/cancel`)

// 微信扫码支付
export const wxpay = (ids:object) => service.get('/api/pay/wechat/scan',{params:ids})

// 修改密码
export const changePsw = (data:object) => service.post('/api/user/update_password',data)

// 获取订单列表
export const orderList = (data:object) => service.get('/api/order',{params:data})

// 公告标段列表
export const afficheABlock = (id:string) => service.get(`/api/affiche/${id}/affiche_a_block`)

// 标段报名
export const doSubmit = (affiche_id:string,affiche_a_block_id:string,data:object) => service.post(`/api/affiche/${affiche_id}/${affiche_a_block_id}/sign_up`,data)

// 用户信息修改
export const userUpdate = (data:object) => service.post('/api/user/update',data)

// 订单支付状态
export const checkStatus = (order_id:string) => service.get(`/api/order/${order_id}/status`)

// 投标情况
export const getMyJoinList = (data:object) =>service.get('/api/sign_up',{params:data})

// 批量删除
export const delMyJoin = (ids:object) =>service.delete('/api/sign_up',{params:ids})

// 短信验证码
export const getTextCode = (phone:object) =>service.get('/api/common/send_code',{params:phone})

// 找回密码
export const foundPsw = (data:object) => service.post('/api/find_back_password',data)

// 首页数据
export const getIndex = () => service.get('/api/common/index_total')

// 免责声明
export const getDisclaimer = (data:object) =>service.get('/api/common/disclaimer',{params:data})

// 报名详情
export const signDetail = (id:string) =>service.get(`/api/sign_up/${id}`)

// 报名更新
export const updateSign = (id:string,data:object) =>service.post(`/api/sign_up/${id}`,data)

// 退出清除token
export const loginOut = () => service.get('/api/logout')